import { lazy, memo, Suspense, useCallback } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import IntentionSettingDialogOnboardingHeader from './IntentionSettingDialogOnboardingHeader'
import { ROUTES } from '../../../routes/routes'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import IntentionSettingDialogHeaderContainer from './IntentionSettingDialogHeaderContainer'
import IntentionSettingDialogJourneysHeader from './IntentionSettingDialogJourneysHeader'
const IntentionSettingForm = lazy(() => import('./IntentionSettingForm'))

export type IntentionSettingDialogProps = {
    variant: 'onboarding' | 'journeys'
    onCompleted: () => void
}

function IntentionSettingDialog(props: IntentionSettingDialogProps) {
    const { variant, onCompleted } = props
    const { gradient } = useTheme()

    const navigate = useCrossAppNavigation()

    // Should be available only for the journeys variant
    const handleDialogClose = useCallback(() => {
        navigate(ROUTES.JOURNEYS)
    }, [navigate])

    return (
        <Dialog
            open={true}
            fullScreen={true}
            PaperProps={{
                sx: {
                    background: gradient.light
                }
            }}
            data-testid="intention-setting-dialog"
        >
            <IntentionSettingDialogHeaderContainer>
                {variant === 'onboarding' && (
                    <IntentionSettingDialogOnboardingHeader />
                )}
                {variant === 'journeys' && (
                    <IntentionSettingDialogJourneysHeader
                        onClose={handleDialogClose}
                    />
                )}
            </IntentionSettingDialogHeaderContainer>

            <DialogContent
                sx={{
                    position: 'relative',
                    pt: 0
                }}
            >
                <Suspense fallback={null}>
                    <IntentionSettingForm
                        mode={'create'}
                        onCompleted={onCompleted}
                    />
                </Suspense>
            </DialogContent>
        </Dialog>
    )
}

export default memo(IntentionSettingDialog)
